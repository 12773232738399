import { LIFE_CYCLE_EVENT_BUS } from 'life-cycle-event-bus';
import 'intersection-observer';

if(location.search.indexOf('debug') !== -1){
    window.isDebugMode = true;
}

if(typeof Array.prototype.flat === "undefined"
    || typeof window.fetch === "undefined") {
    document.addEventListener('DOMContentLoaded', function() {
        const head = document.getElementsByTagName('head')[0];
        const lazyCSS = document.createElement('link');
        const lazyJS = document.createElement('script');

        lazyCSS.href = 'https://assets.thdstatic.com/core/vanilla-overlay/v1.0.11/main.css';
        lazyCSS.rel = 'stylesheet';
        lazyCSS.type = 'text/css';
        lazyJS.type = 'text/javascript';
        lazyJS.onload = function() {
            vanilla_overlay.openOverlay();
        }
        lazyCSS.onload = function() {
            lazyJS.src = 'https://assets.thdstatic.com/core/vanilla-overlay/v1.0.11/index.min.js';
            head.appendChild(lazyJS);
        }
        head.appendChild(lazyCSS);

        if (typeof newrelic !== 'undefined') {
            newrelic.addPageAction('DeprecatedBrowserOverlay');
        }
    });
}

window.LIFE_CYCLE_EVENT_BUS = LIFE_CYCLE_EVENT_BUS;
window.waitForEvent = function(event) {
    return new Promise(resolve => window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on(event, resolve));
};
